
.site-header {
    background-color: $ls-color-primary;
    height: 60px;
    left: 0;
    padding: 12px 30px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.sidebar-left {
    background: $ls-color-secondary;
    height: 100%;
    left: 0;
    padding: 60px 0 10px;
    position: fixed;
    top: 0;
    width: 240px;
    z-index: 30;
}


// ---------------------
.primary-color-bg{
  background-color: #625bdb;
}

.primary-color-text{
  color: #625bdb;
}
.learnbtn-hover-lt:hover{
  color: white;
  background: #655edcc4;
  
}

.blog-section{
  background: #f9f9f900;
  background-color: #f9f9f900;
}

.jumbotron {
    background-image: url('http://localhost:8000/images/login-vector1.svg');
    background-size: cover;
    color: #fff; /* Text color for better contrast */
    text-align: center;
    padding: 100px 0; /* Adjust the padding as needed */
  }

  .jumbotron h1 {
    font-size: 3rem; /* Customize the heading font size */
    color: #fff !important;
  }

  .jumbotron p {
    font-size: 1.5rem; /* Customize the paragraph font size */
  }

  .about-us {
    background-color: #f8f9fa; /* Background color for the "About Us" section */
    padding: 40px 0;
    text-align: center;
  }

  // about us
 
  .about-section {
    background: #ffffff;
    padding: 80px 20px;

    // border: 1px solid #ddd;
  }
  .about-section h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  .about-content {
    overflow: hidden;
  }
  .about-content .col {
    float: left;
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
  }
  .about-content p {
    text-align: justify;
  }
  .button {
    display: block;
    width: 200px;
    margin: 20px auto;
    text-align: center;
    padding: 10px;
    background: blue;
    color: white;
    text-decoration: none;
  }
  .checklist {
    list-style: none;
    padding: 0;
  }
  .checklist li {
    margin-bottom: 10px;
  }
  .checklist li:before {
    content: 'â';
    margin-right: 5px;
    color: green;
  }


  // 
  a.nav-link { cursor: pointer; }
  li.active{
    background: whitesmoke;
    padding: 0px 10px;
  }
   
  .round-image{
    border-radius: 30px;
  } 

  .features-subtitle{
     margin-top: 14px;
  }

  .header-top-style{
    margin-top: 6%; 
  }

  .header-style{
     margin: 0px; 
  }

  .divider-padding{
    padding-bottom: 14px;
  }

  .learnmore-btn-light{
    background: #ffffff14;
    width: 200px;
    margin: 20px auto;
    text-align: center;
    padding: 10px;
    border-radius: 50px;
  } 
  
  .learnmore-btn{
    border-radius: 50px;
    background-color: #625bdb;
    background: #625bdb;
  }


  .features {
    background-color: #f7f7f7e0; /* Background color for the "Features" section */
    padding: 60px 0;
  }

  .feature {
    text-align: center;
    margin-bottom: 30px;
  }

  .feature img {
    max-width: 100%;
    height: auto;
  }
  
  .testimonials {
    background-color: #fff; /* Background color for the "Testimonials" section */
    padding: 60px 0;
    text-align: center;
  }

  .testimonial {
    margin-bottom: 30px;
  }

  .footer-style-main{
     padding: 18px 10px !important;
  }

  .justify-content ul li{
     text-align: justify;
  }

 // - ---------------------- 

.sidebar-body {
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 8px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 8px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid rgba(255, 255, 255, 0.0);
        background-color: rgba(255, 255, 255, 0.3);
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(0,0,0,0);
        border-radius: 8px;
    }
}

.mobile-menu-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 29;
    background: rgba(48, 75, 88, .5);
    display: none;
}

.layout-default .main-content,
.layout-icon-sidebar .main-content {
    padding: 90px 30px 10px 270px;
    min-height: calc(100% - 39px);
}
/*
    Introduced by Abhishek Sharma on 17th January 2019
*/
.layout-default .main-content-with-iframe{
    padding: 90px 30px 10px 100px;
}
/*
    End of introduction by Abhishek Sharma
*/

.site-footer {
    background-color: $white;
    color: #818a91;
    font-size: 13px;
    font-weight: normal;
    padding: 10px 30px 10px 255px;

    a {
        color: $ls-color-black--light;
    }
}

.page-header {
    margin-bottom: 30px;
    position: relative;

    p {
        font-size: 13px;
    }

    select {
        border: none;
    }

    .breadcrumb {
        background: none;
        padding: 0;
    }

    .page-actions {
        position: absolute;
        right: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        margin: auto;
    }
}

.template-container {
    height: 100%;
}

//Layout Horizontal

@media(max-width: $small-breakpoint-below) {

    .site-header {
        padding: 12px 15px;
    }

    .sidebar-left {
        left: -240px;
        transition: left .3s ease;
    }

    .sidebar-open .sidebar-left {
        left: 0;
    }

    .sidebar-open .mobile-menu-overlay {
        display: block;
    }

    .layout-default .main-content,
    .layout-icon-sidebar .main-content {
        padding-left: 15px;
        padding-right: 15px;
    }

    .site-footer {
        padding-left: 15px;
    }

}


